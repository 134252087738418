<template src="./home.html"></template>

<script>
import productCard from '../productCard/productCard';
import header from '../header/header';

export default {
  name: 'home',
  components: {
    productCard,
    recommendationHeader: header,
  },
  data() {
    return {
      isNewAtStoreLoading: false,
      newAtStore: '',
      isRecomendedMaskLoading: false,
      recomendedMask: '',
      productHightlight: '',
      isProductHighlightLoading: false,
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  created() {
    this.$store.dispatch('saveStoreIdCookies', this.$route.query.store_location_id);
    this.getNewAtStore();
    this.getRecomendedMask();
    this.getProductHightlight();
  },
  methods: {
    getNewAtStore() {
      this.isNewAtStoreLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/products/new-at-store`, {
          params: {
            filter: `{"store_location_id":${this.getCookies()}}`,
            limit: 10,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.newAtStore = response.data.data;
          } else {
            this.newAtStore = [];
          }
          this.isNewAtStoreLoading = false;
        })
        .catch((error) => {
          this.newAtStore = [];
          this.isNewAtStoreLoading = false;
        });
    },
    getRecomendedMask() {
      this.isRecomendedMaskLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/products/beauty-profile`, {
          params: {
            filter: `{"store_location_id": ${this.getCookies()}}`,
            limit: 3,
          },
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.recomendedMask = response.data.data;
          } else {
            this.recomendedMask = [];
          }
          this.isRecomendedMaskLoading = false;
        })
        .catch((error) => {
          this.recomendedMask = [];
          this.isRecomendedMaskLoading = false;
        });
    },
    getToken() {
      if (Cookies.get('token') && Cookies.get('token') != undefined) {
        return Cookies.get('token');
      }
    },
    getCookies() {
      if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
        return Cookies.get('store_location_id');
      }
    },
    getProductHightlight() {
      this.isProductHighlightLoading = true;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(
          `/offline-store/store-cms/products?screen=SOCO%20interactive%20recommendation%20machine&store_location_id=${this.getCookies()}`
        )
        .then((response) => {
          if (response.status === 200 && response.data.data.length > 0) {
            this.productHightlight = response.data.data.shift().products;
          } else {
            this.productHightlight = [];
          }
          this.isProductHighlightLoading = false;
        })
        .catch((error) => {
          this.productHightlight = [];
          this.isProductHighlightLoading = false;
        });
    },
    mappingProduct(item) {
      item.manufacturer_name = item.brand;
      item.image_cover = item.product_image_url;
      item.price = item.original_price;
      return item;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'home';
</style>
